<div class="w-100 d-flex flex-wrap align-items-center pt-5">
    <div class="col-md-6 col-2 p-2">
        <div class="banner-text">
            Empower Your Vision,<br>Create with Ease,<br>Heero <span class="color-1">St</span><span class="color-2">ud</span><span class="color-3">io</span>, The Ultimate Low-Code Solution
        </div>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <button routerLink="/session/loginone" class="custom-btn-1 large mx-1">
                See what’s new
            </button>
            <button routerLink="/session/loginone" class="custom-btn-1 large mx-1">
                Start for free
            </button>
        </div>
    </div>
    <div class="col-md-6 col-2 p-2">
        <div class="banner-image">
            <img class="bounce-fly" src="../../../../../assets/img/banner-image-1.png">
        </div>
    </div>
</div>