import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/core/services/users.service';
import { IUser, User } from 'app/shared/models/user';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  readonly pwdRegex = "^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$";
  registrationForm: UntypedFormGroup;
  countryFormGroup: UntypedFormGroup;
  avatarFormGroup: UntypedFormGroup;
  currentUser: IUser;
  currentImage: any;
  private destroyed$ = new Subject<boolean>();



  constructor(
    private fb: UntypedFormBuilder,
    private userService: UsersService,
    public toastrService: ToastrService,
    private translate: TranslateService,
    private router: Router

  ) { }

  ngOnInit() {
    this.createRegistrationForm();
  }

  createRegistrationForm() {
    this.registrationForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }




  get firstName() {
    return this.registrationForm.get('firstName');
  }
  get lastName() {
    return this.registrationForm.get('lastName');
  }

  get email() {
    return this.registrationForm.get('email');
  }

  get password() {
    return this.registrationForm.get('password');
  }
  get avatar() {
    return this.registrationForm.get('avatar');
  }

  onSubmit() {

    if (this.registrationForm.invalid) return;

    let user = new User();

    user = this.registrationForm.value;
    user.login = user.email;

    this.userService.register(user).subscribe(data => {
      this.toastrService.success(
        this.translate.instant('REGISTRED_SUCCESSFULLY')
      );
    }, error => {
      if (error instanceof HttpErrorResponse) {
        if (error.error.detail === "MAIL_EXIST") {
          this.toastrService.error(
            this.translate.instant('MAIL_EXIST')
          );
        }
      }

    });

    this.router.navigate(['/session/loginone']);

  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }


}