import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-capabilities',
  templateUrl: './landing-capabilities.component.html',
  styleUrls: ['./landing-capabilities.component.scss']
})
export class LandingCapabilitiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
