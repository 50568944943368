import { Component, Input, NgModule, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconService } from 'app/shared/services/svg-icon-lib.service';

@Component({
    selector: 'app-icon-svg',
    templateUrl: './icon-svg.component.html',
})

export class IconComponent implements OnInit{
    @Input() svgCode;
    icon;
    constructor(
        private svgIconService:SvgIconService,
        private domSanitizer: DomSanitizer

    ) { }
    ngOnInit() {
        this.getCleanIcon();
    }


    getCleanIcon() {
        this.icon=this.domSanitizer.bypassSecurityTrustHtml(this.svgIconService.getIcon(this.svgCode));
    }
  
  
 
}
@NgModule({ 
declarations : [IconComponent],
exports : [IconComponent]
  
})
export class IconModule {
    
}


