<div class="row w-100 m-0">
    <div class="card card-custom-2">
        <div class="row m-0 w-100">
            <div class="col-md-6 col-12 p-2 h-100">
                <div class="card card-custom-1 h-100">
                    <p>Welcome aboard and thank you for signing in with Heero Studio!</p>
                    <p>We're thrilled to have you join our community of innovators and creators. Get ready to embark on an exciting journey of limitless possibilities.</p>
                    <p>With Heero Studio by your side, turning your ideas into reality has never been easier.</p>
                    <button class="custom-btn-2" routerLink="/projects/create-project">
                        <strong>+</strong> Create new project
                    </button>
                </div>
            </div>
            <div class="col-md-6 col-12 p-2 h-100">
                <div class="card card-custom-1 h-100">
                    <p>Explore our intuitive platform, unlock powerful features, and unleash your creativity. Whether you're a seasoned developer or just getting started, our user-friendly tools are designed to empower you every step of the way.</p>
                    <p>Should you need any assistance or have questions along the path, our dedicated support team is here to help. Feel free to reach out anytime!</p>
                    <button class="custom-btn-2" routerLink="/projects">
                        Explore
                    </button>
                </div>
            </div>
        </div>
        <div class="row m-0 w-100 mt-5">
            <div class="col-12 p-2 text-center">
                <p>Let's redefine what's possible together. Happy building!</p>
                <button class="custom-btn-2" routerLink="/projects/create-project">
                    <strong>+</strong> Create new project
                </button>
            </div>
        </div>
    </div>
</div>