import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaService } from 'app/shared/services/media.service';

@Component({
  selector: 'app-media-handler',
  templateUrl: './media-handler.component.html',
  styleUrls: ['./media-handler.component.scss']
})
export class MediaHandlerComponent implements OnInit {
  _value: any = {};
  @Input()
  set value(val: any) {
    this.valueChange.emit(val);
    this._value = val;
  }
  get value() {
    return this._value;
  }
  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private mediaService: MediaService,
  ) { }

  ngOnInit(): void {
  }
  onSelectFile(event : any) {
    const file: File = event.target.files[0];
    this.mediaService.storeMedia(file).subscribe((res) => {
      this.valueChange.emit(res)
      })
  }
  getImage(image, format) {
    return this.mediaService.downloadMedia(image.id, null);
  }
  annuler(){
    this.value = null
    this.valueChange.emit(null);
  }
}
