<div class="container-fluid d-flex flex-wrap align-items-center bloc-about-background">
    <div class="col-md-6 col-2 p-2">
        <div class="about-image">
            <img class="bounce-fly" src="../../../../../assets/img/web-concept.png">
        </div>
    </div>
    <div class="col-md-6 col-2 p-2 pr-4">
        <div class="card p-2 px-4">
            <h2 class="main-title-style">About</h2>
            <p class="main-paragraph-style">
                Heero Studio, a cutting-edge low-code solution that empowers individuals and teams to bring their innovative ideas to life. With our user-friendly platform, anyone can easily build powerful projects without the need for extensive coding knowledge. Whether you're a seasoned developer or a complete novice, Heero Studio streamlines the entire development process, enabling you to focus on your vision rather than technical complexities. Harness the power of simplicity and efficiency as you transform your concepts into reality. From rapid prototyping to full-scale applications, our low-code project builder provides the tools you need to create with confidence. Embrace the future of software development with Heero Studio and revolutionize the way you build and innovate.
            </p>
        </div>
    </div>


<div class="w-100 d-flex justify-content-center align-items-center my-5">
    <button routerLink="/session/loginone" class="custom-btn-1 large mx-1">
        See what’s new
    </button>
    <button routerLink="/session/loginone" class="custom-btn-1 large mx-1">
        Start for free
    </button>
</div>

</div>