export const environment = {
  production: false,
  apiUrl: 'https://studioh.softparadigm.digital/',
  defaultLang: 'fr',
  defaultLangName: 'Français (France)',
};
export const environmentBuilderH = {
  production: false,
  apiUrl: 'https://konfohome.heero.store/',
  defaultLang: 'fr',
  defaultLangName: 'Français (France)',
  imagePlaceholder: 'https://konfohome.heero.store/assets/img/placeholder.png',
  mediaUrl: 'https://konfohome.heero.store/media/',
  shopUrl: 'https://konfohome.heero.store/',
  emailSaasAdmin: 'admin@heero.com',
  mdpSaasAdmin: 'r2MtWs=x',
  tenantSaasAdmin: 'konfohome',
};

