import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

import { LoginoneComponent } from './loginone/loginone.component';
import { SessionRoutes } from './session.routing';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './landing-page/home/home.component';
import { MainHeaderComponent } from './landing-page/components/main-header/main-header.component';
import { MainFooterComponent } from './landing-page/components/main-footer/main-footer.component';
import { LandingBannerComponent } from './landing-page/components/landing-banner/landing-banner.component';
import { LandingAboutComponent } from './landing-page/components/landing-about/landing-about.component';
import { LandingCapabilitiesComponent } from './landing-page/components/landing-capabilities/landing-capabilities.component';
import { LandingExploreStudioComponent } from './landing-page/components/landing-explore-studio/landing-explore-studio.component';
import { RegisterComponent } from './register/register.component';
import { WelcomingPageComponent } from './welcoming-page/welcoming-page.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { IconModule } from 'app/shared/components/icons/icon-svg-component';
import {TooltipModule} from 'primeng/tooltip';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),
        RouterModule.forChild(SessionRoutes),
        NgbDropdownModule,
        IconModule,
        TooltipModule
    ],
    declarations: [
        LoginoneComponent,
        HomeComponent,
        RegisterComponent,
        MainHeaderComponent,
        MainFooterComponent,
        LandingBannerComponent,
        LandingAboutComponent,
        LandingCapabilitiesComponent,
        LandingExploreStudioComponent,
        WelcomingPageComponent,
        WelcomingPageComponent,
        SidebarMenuComponent
    ],
    exports: [
        MainHeaderComponent,
        MainFooterComponent,
        SidebarMenuComponent
    ]
})

export class SessionDemoModule { }
