<div class="login-register-background">
  <div class="container d-flex flex-wrap align-items-center justify-content-center">
    <div class="col-lg-6 col-12 my-3 p-2">
      <h2 class="main-paragraph-style mb-2 w-100">{{'Sign in to Studio H'|translate}}</h2>
      <form class="login-form" [formGroup]="loginForm">
        <div>
          <div class="alert alert-danger" *ngIf="authenticationError">
            <strong>{{'ERROR-MESSAGE1' | translate}}</strong> {{'ERROR-MESSAGE2' | translate}}
          </div>
        </div>
        <div class="form-group">
          <input type="email" class="form-control session-input-font" placeholder="Login" formControlName="username" required>
        </div>
        <div class="form-group">
          <input type="password" class="form-control session-input-font" name="password" placeholder="Password" formControlName="password" required>
        </div>
        <div class="login-meta mb-3">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-6">
              <div class="custom-control custom-checkbox">
                <label class="mb-2 mr-sm-2 mb-sm-0 d-flex ">
                  <input class="custom-control-input ng-untouched ng-pristine ng-valid" type="checkbox" id="login-remember" formControlName="rememberMe">
                  <span class="custom-control-label"></span>
                  <span class="custom-control-description d-inline-block align-text-top cursur-pointer">{{'Remember Me'|translate}}</span>
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 text-left text-sm-right">
              <a routerLink="/session/forgot-password" class="text-muted">{{'Forgot Password'|translate}}</a>
              <span> | </span>
              <a routerLink="/session/register" class="text-muted">{{'New account'|translate}}</a>
            </div>
          </div>
        </div>
        <button type="submit" (click)="login()" class="custom-btn-1 float-right">{{'LOGIN' | translate}}</button>
      </form>
    </div>
  </div>
</div>