<div class="container-fluid header-top">
    <div class="site-id-area">
        <div class="main-logo" [routerLink]="mainRoute">
            <span>Studio H</span><sup>#</sup>
        </div>

        <h2 class="page-title">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="14" x="0" y="0" viewBox="0 0 48 48" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g data-name="7. diagram"><path d="M17 1h14v10H17zM1 29h14v10H1zM39 31h8v6h-8zM39 41h8v6h-8zM39 21h8v6h-8z" fill="#000000" opacity="1" data-original="#000000" class=""></path><path d="M35 25a1 1 0 0 0 0-2H25v-8a1 1 0 0 0-2 0v2H8a1 1 0 0 0-1 1v7a1 1 0 0 0 2 0v-6h14v14h-4a1 1 0 0 0 0 2h4v9a1 1 0 0 0 1 1h11a1 1 0 0 0 0-2H25v-8h10a1 1 0 0 0 0-2H25v-8z" fill="#000000" opacity="1" data-original="#000000" class=""></path></g></g></svg>
            {{title | translate}}
        </h2>
    </div>
    <div class="account-area">

        <button *ngIf="!userPresent"  [routerLinkActive]="'is-active'" routerLink="/session/loginone" class="custom-btn-1 mr-2">Start for free</button>

        <button *ngIf="userPresent"  [routerLinkActive]="'is-active'" routerLink="/projects/create-project" class="custom-btn-1 mr-2">Create project</button>

        <div *ngIf="userPresent" class="dropdown mr-2">

            <div class="dropdown-toggle manage-projects" id="dropdownManageProjects" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <app-icon-svg [svgCode]="'cog'"></app-icon-svg>
            </div>
            <div class="dropdown-menu dropdown-menu-right custom-dropdown-style" aria-labelledby="dropdownManageProjects">
                <div class="list-dropdown-items">
                    <a  [routerLinkActive]="'is-active'" routerLink="/projects">
                        <app-icon-svg class="stroke" [svgCode]="'projects'"></app-icon-svg>
                        {{ "PROJECTS" | translate }}
                    </a>
                    <a  [routerLinkActive]="'is-active'" routerLink="/blueprints">
                        <app-icon-svg class="stroke" [svgCode]="'blueprints'"></app-icon-svg>
                        {{ "BLUEPRINTS" | translate }}
                    </a>
                    <a  [routerLinkActive]="'is-active'" routerLink="/modeler">
                        <app-icon-svg class="stroke" [svgCode]="'modeler'"></app-icon-svg>
                        {{ "BUSINESS_PROCESSES" | translate }}
                    </a>
                    <a  [routerLinkActive]="'is-active'" routerLink="/blueprints/blueprintsVersions">
                        <app-icon-svg class="stroke" [svgCode]="'blueprintsVersions'"></app-icon-svg>
                        Blueprints versions
                    </a>
                    <a  [routerLinkActive]="'is-active'" routerLink="/generated-projects">
                        <app-icon-svg class="stroke" [svgCode]="'generatedProjects'"></app-icon-svg>
                        Generated projects
                    </a>
                </div>
            </div>
            
            
            
        </div>

        <div class="dropdown">

            <div class="dropdown-toggle manage-account" id="dropdownManageAccount" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <app-icon-svg [svgCode]="'user'"></app-icon-svg>
            </div>
            <div class="dropdown-menu dropdown-menu-right custom-dropdown-style" aria-labelledby="dropdownManageAccount">
                <div class="list-dropdown-items">
                    <ng-container *ngIf="!userPresent">
                        <a  [routerLinkActive]="'is-active'" routerLink="/session/register">
                            <app-icon-svg class="stroke" [svgCode]="'register'"></app-icon-svg>
                            Register
                        </a>
                        <a  [routerLinkActive]="'is-active'" routerLink="/session/loginone">
                            <app-icon-svg class="stroke" [svgCode]="'login'"></app-icon-svg>
                            Login
                        </a>
                    </ng-container>
                    <ng-container *ngIf="userPresent">
                        <a  [routerLinkActive]="'is-active'" [routerLink]="accountUrl">
                            <app-icon-svg class="stroke" [svgCode]="'user'"></app-icon-svg>
                            My profile
                        </a>
                        <a  [routerLinkActive]="'is-active'" routerLink="/accountsManagement/users">
                            <app-icon-svg class="fill" [svgCode]="'users'"></app-icon-svg>
                            Users
                        </a>
                        <a  [routerLinkActive]="'is-active'" routerLink="/accountsManagement/accounts">
                            <app-icon-svg class="fill" [svgCode]="'accounts'"></app-icon-svg>
                            Organizations
                        </a>
                        <a (click)="logOut()">
                            <app-icon-svg class="stroke" [svgCode]="'logout'"></app-icon-svg>
                            Logout
                        </a>
                    </ng-container>
                </div>
            </div>
                
        </div>

    </div>
</div>