import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIService } from 'app/service/api.service';
import { IAccountsManager } from 'app/shared/models/accountsManager';
import { IUser } from 'app/shared/models/user';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor( private http : HttpClient)  { }

  getUsers () : Observable<any>{
    return this.http.get<any>(environment.apiUrl+"api/studio/users",{observe : 'response'})
  }
  register(user : IUser) {
    return this.http.post(environment.apiUrl+"api/register", user);
  }
  postUser(user : IUser) {
    return this.http.post(environment.apiUrl+"api/studio/users", user);
  } 
  deleteUsers( login : string) {
    return this.http.delete(environment.apiUrl+"api/studio/users/"+ login)
  }
  getUserBylogin(login : string) : Observable<IUser>{
    return this.http.get(environment.apiUrl+"api/studio/users/"+ login)
  }
  putUser(user: IUser) {
    return this.http.put(environment.apiUrl+"api/studio/users/"+user.id, user)
  }
}
