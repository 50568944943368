import {Component, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserAccountService} from './service/auth/user-account.service';
import {Router} from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import { ThemeBuilderHService } from './shared/services/theme-builder-h.service';
import { DEFAULT_LANGUAGE, DEFAULT_LOCALE, VALUE_CURRENCY_BUILDER_H } from './shared/constants';



@Component({
  selector: 'chankya-app',
  template: '<router-outlet></router-outlet>',
  encapsulation: ViewEncapsulation.None
})

export class ChankyaAppComponent {
  defaultLang = 'ENGLISH_UK';
  currentLocale = 'en';
  loading: boolean;
  private account;

  constructor(private translate: TranslateService, private router: Router,
              private userAccountService: UserAccountService,
              private localStorage: LocalStorageService,
              private themeBuilderHService : ThemeBuilderHService
  ) {
    translate.addLangs(['fr', 'en']);
    translate.setDefaultLang('en');
    const browserLang= translate.getBrowserLang();
  }

  setCurrentLanguage() {
    if (this.localStorage.retrieve('localeId')) {
      this.translate.use(this.localStorage.retrieve('localeId'));
      
    } else {
      this.translate.use(this.translate.defaultLang);
      this.localStorage.store('language', this.defaultLang);
      this.localStorage.store('localeId', this.currentLocale);
   

    }
  }
  changeTheme(theme: string) {
    this.themeBuilderHService.switchTheme(theme);
  }
  changeGlobalStyles(theme: string) {
    this.themeBuilderHService.switchGlobalStyles(theme);
  }
  ngOnInit(): void {
    this.setCurrentLanguage();
    const token = localStorage.getItem('authenticationToken') || localStorage.getItem('authenticationToken');
    if (token) {
         this.userAccountService.identity().then((account) => {
             this.account = account;
         });
    }
    this.changeTheme('nova-alt')
    this.changeGlobalStyles('global-styles')
  }
}

