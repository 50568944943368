import { Injectable } from '@angular/core';
import { UserAccountService } from '../../service/auth/user-account.service';
import { Router } from '@angular/router';
import { AuthServerProvider } from '../../service/auth/auth-jwt.service';
import { environment, environmentBuilderH } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private accountService: UserAccountService,
    private authServerProvider: AuthServerProvider,
    private router: Router,
    private http: HttpClient
  ) {}

  login(credentials, callback?) {
    const cb = callback || function () {};

    return new Promise((resolve, reject) => {
      this.authServerProvider.login(credentials).subscribe(
        (data) => {
          this.accountService.identity().then((account) => {
            resolve(data);
          });
          return cb();
        },
        (err) => {
          // this.logout();
          reject(err);
          return cb(err);
        }
      );
    });
  }
  loginSassAdmin() {
    const credentials = {
      username: environmentBuilderH.emailSaasAdmin,
      password: environmentBuilderH.mdpSaasAdmin,
      rememberMe: true,
      fcmToken: null,
    };
    const headers = new HttpHeaders({
      'X-Tenant': environment.production ? 'konfohome' : environmentBuilderH.tenantSaasAdmin,
    });
    return this.http.post(
      environmentBuilderH.apiUrl + 'api/authentications/back-office',
      credentials,
      {
        headers,
      }
    );
  }

  logout() {
    this.authServerProvider.logout().subscribe(null, null, () => {
      this.accountService.authenticate(null);
      this.router.navigate(['/session/home']);
    });
  }
}
