<div class="sidebar-menu-container">
  <a
    [style.margin-top]="!isSideBarOpen ? '5px' : ''"
    [routerLinkActive]="'is-active'"
    routerLink="/projects"
    [pTooltip]="'PROJECTS' | translate " [appendTo]="'body'"
  >
    <app-icon-svg class="stroke" [svgCode]="'projects'"></app-icon-svg>
    <ng-container *ngIf="isSideBarOpen"
      >{{ "PROJECTS" | translate }} 
    </ng-container>
  </a>
  <a
    [style.margin-top]="!isSideBarOpen ? '5px' : ''"
    [routerLinkActive]="'is-active'"
    routerLink="/blueprints"
    [pTooltip]="'BLUEPRINTS' | translate " [appendTo]="'body'"
  >
    <app-icon-svg class="stroke" [svgCode]="'blueprints'"></app-icon-svg>
    <ng-container *ngIf="isSideBarOpen"
      >{{ "BLUEPRINTS" | translate }}
    </ng-container>
  </a>
  <a
  [style.margin-top]="!isSideBarOpen ? '5px' : ''"
  [routerLinkActive]="'is-active'"
  routerLink="/generated-projects"
  [pTooltip]="'Generated projects' | translate " [appendTo]="'body'"
  >
    <app-icon-svg class="stroke" [svgCode]="'generatedProjects'"></app-icon-svg>
    <ng-container *ngIf="isSideBarOpen"
      >{{ "Generated projects" | translate }}
    </ng-container>
  </a>
  <a
    [routerLinkActive]="'is-active'"
    [style.margin-top]="!isSideBarOpen ? '5px' : ''"
    routerLink="/builder"
    [queryParams]="{
      applicationId: applicationIdDesktop,
      defaultPage: defaultPageIdDesktop
    }"
    [pTooltip]="'WEB_BUILDER' | translate " [appendTo]="'body'"
  >
    <app-icon-svg class="stroke" [svgCode]="'builderHDesktop'"></app-icon-svg>
    <ng-container *ngIf="isSideBarOpen"  
      >{{ "WEB_BUILDER" | translate }}
    </ng-container>
  </a>
  <a
    [routerLinkActive]="'is-active'"
    [style.margin-top]="!isSideBarOpen ? '5px' : ''"
    routerLink="/builder"
    [queryParams]="{
      applicationId: applicationIdMobile,
      defaultPage: defaultPageIdMobile
    }"
    [pTooltip]="'MOBILE_BUILDER' | translate " [appendTo]="'body'"
  >
    <app-icon-svg class="stroke" [svgCode]="'builderHMobile'"></app-icon-svg>
    <ng-container *ngIf="isSideBarOpen"
      >{{ "MOBILE_BUILDER" | translate }}
    </ng-container>
  </a>
</div>
