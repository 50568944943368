import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MediaHandlerComponent } from './media-handler/media-handler.component';
import {MatDialogModule} from '@angular/material/dialog';




@NgModule({
  declarations: [
    MediaHandlerComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatDialogModule
  ],
  exports: [
    MediaHandlerComponent
  ]
})
export class SharedModuleModule { }
