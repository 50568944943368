import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IMedia, Media } from "../models/media.model";
import { environment } from "environments/environment";

@Injectable({ providedIn: 'root' })
export class MediaService {

  constructor(private http: HttpClient) {
  }

  public create(media: IMedia) {
    return this.http.post(environment.apiUrl + 'api/media', media);
  }

  downloadMedia(mediaId: string, format: string): any {
    return environment.apiUrl + 'api/export-media/' + mediaId + ' /' + format;
  }

  public getJson(path: string): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/export-json-to-string/' + path);
  }

  storeMedia(file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post(environment.apiUrl + 'api/upload-media', formdata);
  }
  storeMediaWithType(file: File, type): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post(environment.apiUrl + 'api/upload-media/' + type, formdata);
  }

  storeMediaUrl(url: string): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/upload-media-url', url, { observe: 'response' });
  }
  storeMediaUrlWithType(url: string, type): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/upload-media-url/' + type, url, { observe: 'response' });
  }

  deleteMedia(id: string): any {
    return this.http.delete(environment.apiUrl + 'api/media/' + id);
  }

  update(media: Media): Observable<IMedia> {
    return this.http.put(environment.apiUrl + 'api/media/', media);
  }
}
