import {Injectable} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';


export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  label?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  label?: string;
  children?: ChildrenItems[];
}




const MENUITEMS = [
  {
    state: 'modeler',
    name: 'MODELER',
    type: 'link',
    icon: 'fa fa-desktop'
  },
  {
    state: 'accountsManagement',
    name: 'Accounts Management',
    type: 'sub',
    icon: 'icon-people icons',
    children: [
      { state: 'users', name: 'USERS' },
      { state: 'accounts', name: 'ACCOUNTS' }
    ]

  },
  {
    state: 'blueprints',
    name: 'BLUEPRINTS',
    type: 'sub',
    icon: 'fa fa-file-text-o',
    children: [
      { state: 'blueprints', name: 'BLUEPRINTS' },
      { state: 'blueprintsVersions', name: 'BLUEPRINTS_VERSIONS' }
    ]

  },
  {
    state: 'projects',
    name: 'PROJECTS',
    type: 'link',
    icon: 'fa fa-desktop'
  
  },
  {
    state: 'generated-projects',
    name: 'GENERATED_PROJECTS',
    type: 'link',
    icon: 'fa fa-desktop'
  
  }




];
const MODULE_MENU_ITEMS = []
@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
  add(menu: any) {
    MENUITEMS.push(menu);
  }
}

@Injectable()
export class ModuleMenuItems {
  getAll(): Menu[] {
    return MODULE_MENU_ITEMS;
  }

  add(menu: any) {
    MODULE_MENU_ITEMS.push(menu);
  }
}
