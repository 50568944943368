import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {IUser} from "../../shared/models/user";


@Injectable({
  providedIn: 'root'
})
export class UserImplService {


  constructor(private  $sessionStorage: SessionStorageService,
              private  $localStorage: LocalStorageService,
              private http: HttpClient) {
  }

  getCurrentUser(): IUser {
    return this.$localStorage.retrieve('user') || this.$sessionStorage.retrieve('user');
  }

  getAllCustomUsers() {
    return this.http.get<any[]>(environment.apiUrl + 'api/'+'custom-users-participants-admins');
  }


}
