import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  APPLICATION_ID_DESKTOP,
  APPLICATION_ID_MOBILE,
  DEFAULT_PAGE_ID_DESKTOP,
  DEFAULT_PAGE_ID_MOBILE,
} from 'app/shared/constants';
import { IUser } from 'app/shared/models/user';
import { SideBarService } from 'app/shared/services/sidebar.service';
import { ReplaySubject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
  user: IUser;
  isSideBarOpen = true;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  readonly applicationIdDesktop = APPLICATION_ID_DESKTOP;
  readonly applicationIdMobile = APPLICATION_ID_MOBILE;
  readonly defaultPageIdDesktop = DEFAULT_PAGE_ID_DESKTOP;
  readonly defaultPageIdMobile = DEFAULT_PAGE_ID_MOBILE;
  constructor(private sideBarService : SideBarService  ) {}

  ngOnInit(): void {
    this.listenToStateSideBar();

  }
  listenToStateSideBar() {
    this.sideBarService.sidebarValue.pipe(takeUntil(this.destroyed$)).subscribe((state)=> {
      this.isSideBarOpen = state
    })
  }
}
