import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PageTitleService } from 'app/core/page-title/page-title.service';
@Component({
  selector: 'app-welcoming-page',
  templateUrl: './welcoming-page.component.html',
  styleUrls: ['./welcoming-page.component.scss']
})
export class WelcomingPageComponent implements OnInit {


  constructor(
    private pageTitleService: PageTitleService,
  ) { }

  ngOnInit(): void {
    this.pageTitleService.setTitle('Dashboard');
  }

}
