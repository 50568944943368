import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SideBarService } from 'app/shared/services/sidebar.service';
import { SessionStorageService } from 'ngx-webstorage';
import { ReplaySubject, takeUntil } from 'rxjs';
@Component({
  selector: 'chankya-layout',
  templateUrl: './main.html',
  styleUrls: ['./main.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainComponent implements OnInit, OnDestroy {
  isSideBarOpen = true;
  userPresent = false;
  hideFooter = false

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private sessionStorage: SessionStorageService,
    private sideBarService: SideBarService,
    private router : Router,
  ) {
    this.hideFooterInsidePageBuilder()
    
  }

  ngOnInit() {
    this.checkUserExistance();
    this.listenToStateSideBar();

  }
  hideFooterInsidePageBuilder() {
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event?.url;
        const builderRegExp = /\/builder/;
        if (builderRegExp.test(currentRoute)) {
          this.hideFooter = true;
        } else this.hideFooter = false;
      }
    });
  }
  checkUserExistance() {
    this.sessionStorage.retrieve('user') != undefined
      ? (this.userPresent = true)
      : (this.userPresent = false);

    this.sessionStorage.observe('user').subscribe((val) => {
      val != undefined ? (this.userPresent = true) : (this.userPresent = false);
    });
  }
  listenToStateSideBar() {
    this.sideBarService.sidebarValue.pipe(takeUntil(this.destroyed$)).subscribe((state)=> {
      this.isSideBarOpen = state
    })
  }
  toggleSideBar() {
    this.isSideBarOpen = !this.isSideBarOpen;
    this.sideBarService.changeStateSidebar(this.isSideBarOpen);
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
