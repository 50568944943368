import { Routes } from '@angular/router';

import { LoginoneComponent } from './loginone/loginone.component';
import { HomeComponent } from './landing-page/home/home.component';
import { RegisterComponent } from './register/register.component';
import { WelcomingPageComponent } from './welcoming-page/welcoming-page.component';

export const SessionRoutes: Routes = [{
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
}, {
    path: '',
    children: [
        {
            path: 'loginone',
            component: LoginoneComponent
        },
        {
            path: 'home',
            component: HomeComponent
        },
        {
            path: 'register',
            component: RegisterComponent
        },
        {
            path: 'studio',
            component: WelcomingPageComponent
        }
    ]
}];
