import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItems } from 'app/core/menu/menu-items/menu-items';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { UserImplService } from 'app/core/services/user-impl.service';
import { LoginService } from 'app/service/auth/login.service';
import { UserAccountService } from 'app/service/auth/user-account.service';
import { IUser } from 'app/shared/models/user';
import { MediaService } from 'app/shared/services/media.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  imagePath = '../../../../../assets/img';
  title = '';
  url: string;
  defaultLang = 'en';
  currentLang = 'en';
  private _router: Subscription;
  photoSrc: string;
  updated = false;
  user: IUser;
  id: string;
  userHavePhoto = false;
  account;
  authority;
  menu;
  userPresent = false;
  accountUrl: string = '/accountsManagement/createAccount/ACCOUNTS_MANAGER';
  mainRoute = '';

  constructor(
    private pageTitleService: PageTitleService,
    private authService: LoginService,
    private menuItems: MenuItems,
    public translate: TranslateService,
    private router: Router,
    private localStorage: LocalStorageService,
    private userImplService: UserImplService,
    private userAccountService: UserAccountService,
    private mediaService: MediaService,
    private sessionStorage: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.titleListener();
    this.checkUserExistance();

    this.localStorage.observe('localeId').subscribe((value) => {
      this.currentLang = value;
    });

    if (this.userImplService.getCurrentUser()) {
      this.user = this.userImplService.getCurrentUser();

      this.authority = this.user.authorities;

      this.id = this.user.id;

      this.userImageObserve();

      this.setCurrentLanguage();

      this.userAccountService.identity().then((account) => {
        this.account = account;
        this.accountUrl =
          '/accountsManagement/createAccount/' + this.account.id;
      });

      this.menu = this.menuItems.getAll();

      this._router = this.router.events
        .pipe(filter((event: Event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.url = event.url;
        });

      this.router.events
        .pipe(filter((event: Event) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          if (event.url === '/session/home') {
            this.logOutFromBack();
          }
        });

      this.setCurrentLanguage();
    } else if (this.router.url.split('-')[0] === '/reset') {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/session/home']);
    }
  }

  switchmainRoute() {
    if (this.userPresent == true) {
      this.mainRoute = '/session/studio';
    } else {
      this.mainRoute = '/session/home';
    }
  }

  checkUserExistance() {
    this.sessionStorage.retrieve('user') != undefined
      ? (this.userPresent = true)
      : (this.userPresent = false);
    this.switchmainRoute();
    this.sessionStorage.observe('user').subscribe((val) => {
      val != undefined ? (this.userPresent = true) : (this.userPresent = false);
      this.switchmainRoute();
    });
  }

  titleListener() {
    this.pageTitleService.title.subscribe((title: string) => {
      this.title = title;
    });
  }

  setCurrentLanguage() {
    this.localStorage.retrieve('localeId') !== null
      ? (this.currentLang = this.localStorage.retrieve('localeId'))
      : (this.currentLang = this.defaultLang);
    this.translate.use(this.currentLang);
  }

  logOut() {
    this.authService.logout();
  }

  logOutFromBack() {
    this.authService.logoutFromBack();
  }

  goToProfile() {
    if (this.authority.indexOf('ROLE_ADVISOR') > -1) {
      this.router.navigate(['/manageUsers/advisors/edit/', this.id]);
    } else {
      this.router.navigate(['/manageUsers/admins/edit/', this.id]);
    }
  }

  setLocal(lang) {
    if (lang === 'en') {
      this.localStorage.store('language', 'ENGLISH_UK');
      this.localStorage.store('localeId', 'en');
      this.translate.use('en');
    } else {
      this.localStorage.store('language', 'FRENCH_FRANCE');
      this.localStorage.store('localeId', 'fr');
      this.translate.use('fr');
    }
  }

  getUserImage() {
    if (this.user?.photo?.id) {
      return this.mediaService.downloadMedia(this.user.photo.id, null);
    }
  }
  userImageObserve() {
    if (this.user.photo) {
      this.userHavePhoto = true;
    }
    this.sessionStorage.observe('user').subscribe((value) => {
      if (value.photo) {
        this.user = value;
        this.userHavePhoto = true;
      } else {
        this.userHavePhoto = false;
      }
    });
  }

  ngOnDestroy() {
    if (this._router) this._router.unsubscribe();
  }
}
