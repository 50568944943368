import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideBarService {

    private sidebar$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
    changeStateSidebar(data: boolean) {
        this.sidebar$.next(data);
      }
      get sidebarValue() {
        return this.sidebar$.asObservable();
      }

}