import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MenuItem} from 'primeng/api';

@Injectable()
export class CustomBreadcrumbService {
  public crumbs: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>(null);

  setCrumbs(value: MenuItem[]) {
    this.crumbs.next(value);
  }
}
