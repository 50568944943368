<div class="container-fluid bg-explore-studio-bloc py-5">
    <h2 class="main-title-style main-title-style-small text-center">Explore Heero Studio, the Ultimate<br>Low-Code Project Platform</h2>
    <div class="explore-studio-bloc-btn-container">
        <div class="col-lg-7 col-12">
            <div class="text-explore-studio-bloc">
                Discover Limitless Possibilities: Start Building for Free with Heero Studio!
            </div>
        </div>
        <div class="col-lg-5 col-12">
            <div class="w-100 d-flex justify-content-center align-items-center my-4">
                <button routerLink="/session/loginone" class="custom-btn-1 larger">
                    Explore Heero Studio
                </button>
            </div>
            <div class="w-100 d-flex justify-content-center align-items-center my-4">
                <button routerLink="/session/loginone" class="custom-btn-1 larger">
                    Start Building Apps
                </button>
            </div>
        </div>
    </div>
</div>