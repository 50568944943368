<div class="login-register-background">
    <div class="container d-flex flex-wrap align-items-center justify-content-center">
      <div class="col-lg-6 col-12 my-3 p-2">
        <h2 class="main-paragraph-style mb-2 w-100">{{'Register to Project Studio'|translate}}</h2>

        <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <input class="form-control session-input-font" type="text" id="firstName" formControlName="firstName" placeholder="First Name">
              <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                <div *ngIf="firstName.errors.required">First Name is required.</div>
              </div>
            </div>
            <div class="form-group">
              <input class="form-control session-input-font" type="text" id="lastName" formControlName="lastName" placeholder="Last Name">
              <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                <div *ngIf="lastName.errors.required">Last Name is required.</div>
              </div>
            </div>
            <div class="form-group">
              <input class="form-control session-input-font" type="email" id="email" formControlName="email" placeholder="Email">
              <div *ngIf="email.invalid && (email.dirty || email.touched)">
                <div *ngIf="email.errors.required">Email is required.</div>
                <div *ngIf="email.errors.email">Invalid email format.</div>
              </div>
            </div>
            <div class="form-group">
              <input class="form-control session-input-font" type="password" id="password" formControlName="password" placeholder="Password">
              <div *ngIf="password.invalid && (password.dirty || password.touched)">
                <div *ngIf="password.errors.required">Password is required.</div>
                <div *ngIf="password.errors.minlength">Password should be at least 6 characters long.</div>
              </div>
            </div>

          
            <button type="submit" class="custom-btn-1 float-right" [disabled]="registrationForm.invalid">Register</button>
          </form>

      </div>
    </div>
  </div>