import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-banner',
  templateUrl: './landing-banner.component.html',
  styleUrls: ['./landing-banner.component.scss']
})
export class LandingBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
