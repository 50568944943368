<app-main-header></app-main-header>
<div class="row-content-area">
  <div
  *ngIf="userPresent"
  class="side-bar-opener"
  (click)="toggleSideBar()"
  [class.is-open]="isSideBarOpen && userPresent"
  [class.is-closed]="!isSideBarOpen && userPresent"
>
  <i *ngIf="!isSideBarOpen" class="fa fa-chevron-right" aria-hidden="true"></i>
  <i *ngIf="isSideBarOpen" class="fa fa-chevron-left" aria-hidden="true"></i>
</div>
  <div
    *ngIf="userPresent"
    class="sidebar-menu-wrapper"
    [class.is-open]="isSideBarOpen && userPresent"
    [class.is-closed]="!isSideBarOpen && userPresent"
  >
    <app-sidebar-menu></app-sidebar-menu>
  </div>

  <div
    class="main-content-wrapper"
    [class.dashboard-content]="userPresent"
    [class.is-open]="isSideBarOpen && userPresent"
    [class.is-closed]="!isSideBarOpen && userPresent"
  >
    <router-outlet></router-outlet>
  </div>
</div>
<app-main-footer *ngIf="!hideFooter"></app-main-footer>
