import { environment, environmentBuilderH } from "environments/environment";


export const ENV_HOSTURL =
  typeof window !== 'undefined' ? window.location.origin + '/' : '';
export const LOCAL_STORAGE_SERVICE_LANGUAGE_KEY = 'localeIdBuilderH';
export const LOCAL_STORAGE_SERVICE_CURRENCY_KEY = 'currency';
export const DEFAULT_CURRENCY = 'TND';
export const ENV_DEFAULT_FAMILY = 'DEFAULT_FAMILY';
export const CONTEXT_ID_FOR_PRODUCT = 'FRENCH_FRANCE,DESKTOP';
export const DEFAULT_LOCALE = 'FRENCH_FRANCE';
export const DEFAULT_LANGUAGE = 'fr';
export const APPLICATION_ID_DESKTOP = environment.production? 'DESKTOP_KONFOHOME' :'DESKTOP_'+environmentBuilderH.tenantSaasAdmin.toUpperCase();
export const APPLICATION_ID_MOBILE =  environment.production? 'MOBILE_KONFOHOME' : 'MOBILE_TEST'+environmentBuilderH.tenantSaasAdmin.toUpperCase();
export const DEFAULT_PAGE_ID_DESKTOP = 'HOME_PAGE_DESKTOP';
export const DEFAULT_PAGE_ID_MOBILE = 'HOME_PAGE_MOBILE';
export const VALUE_CURRENCY_BUILDER_H = {
  createdBy: null,
  createdDate: '2023-10-26T11:32:14.050Z',
  deleted: null,
  seoData: null,
  id: 'TND',
  code: '788',
  name: {
    localizedValues: { FRENCH_FRANCE: 'Dinar', ENGLISH_UK: 'Dinar' },
    allValues: ['Dinar', 'Dinar'],
  },
  isoCode: 'TND',
  signAfterAmount: true,
  thousandSeparator: ',',
  decimalSeparator: '.',
  numberOfDigitAfterDecimalSign: 3,
  active: true,
};
