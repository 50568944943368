import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserAccountService } from '../../service/auth/user-account.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IUser, User } from '../../shared/models/user';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './login.service';
import { CustomTableEmitter } from '../../core/services/customTableEmitter';
import { PageTitleService } from 'app/core/page-title/page-title.service';

@Component({
  selector: 'ms-loginone-session',
  templateUrl: './loginone-component.html',
  styleUrls: ['./loginone-component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginoneComponent implements OnInit {
  customer: IUser;
  email: string;
  password: string;
  authenticationError: boolean;
  rememberMe: boolean;
  submitted = false;
  account: IUser;
  loginForm = this.fb.group({
    username: ['', Validators.compose([Validators.required])],
    password: ['', Validators.compose([Validators.required])],
    rememberMe: [false],
  });
  numberOfAttempts = 0;
  hiddenRecaptcha = true;
  forgotPasswordSubmitted = false;
  isValidUsername = true;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private loginService: LoginService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private userAccountService: UserAccountService,
    private fb: UntypedFormBuilder,
    private eventEmitter: CustomTableEmitter,
    public toastrService: ToastrService,
    private pageTitleService: PageTitleService
  ) {}

  ngOnInit() {
    this.pageTitleService.setTitle('Login');
    this.customer = new User();
    this.rememberMe = false;
  }

  checkPasswords(group: UntypedFormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  login() {
    this.submitted = true;
    this.forgotPasswordSubmitted = false;
    if (this.loginForm.invalid) {
      return;
    }
    this.loginService
      .login({
        username: this.loginForm.get('username').value,
        password: this.loginForm.get('password').value,
        rememberMe: this.loginForm.get('rememberMe').value,
      })
      .then(() => {
        // this.loginService.loginSassAdmin().subscribe(
        //   (accountSaas: any) => {
            this.authenticationError = false;
            this.eventEmitter.next({
              name: 'authenticationSuccess',
              content: 'Sending Authentication Success',
            });
            this.userAccountService.identity().then((account) => {
              this.account = account;
              this.loginForm.get('rememberMe').value
                ? this.localStorage.store('user', account)
                : this.sessionStorage.store('user', account);
            });
            // this.loginForm.get('rememberMe').value
            //   ? this.localStorage.store(
            //       'authenticationTokenSaas',
            //       accountSaas?.id_token
            //     )
            //   : this.sessionStorage.store(
            //       'authenticationTokenSaas',
            //       accountSaas?.id_token
            //     );
          // },
          // (err) => {
            this.numberOfAttempts = this.numberOfAttempts + 1;
            if (this.numberOfAttempts > 3) {
              this.hiddenRecaptcha = false;
            }
            this.authenticationError = true;
        //   // }
        // );
        this.router.navigate(['/session/studio']);
      })
      .catch(() => {
        this.numberOfAttempts = this.numberOfAttempts + 1;
        if (this.numberOfAttempts > 3) {
          this.hiddenRecaptcha = false;
        }
        this.authenticationError = true;
      });
  }

  forgotPassword(): void {
    this.forgotPasswordSubmitted = true;
    if (this.loginForm.get('username').value === '') {
      return;
    }
    this.userAccountService
      .requestPasswordReset(this.loginForm.get('username').value)
      .subscribe(
        (res) => {
          this.toastrService.success(
            this.translate.instant('RESET_LINK_PASSWORD_SUCCESSFULLY')
          );
          this.forgotPasswordSubmitted = false;
        },
        (err) => {
          this.isValidUsername = false;
        }
      );
  }
}