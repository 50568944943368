import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
@Injectable({
  providedIn: "root",
})
export class ThemeBuilderHService {
  constructor(@Inject(DOCUMENT) private document: Document) {}
  switchTheme(theme: string) {
    let themeLink = this.document.getElementById(
      "app-theme"
    ) as HTMLLinkElement;
    if (themeLink && theme) {
      themeLink.href = theme + ".css";
    } else themeLink.href = "";
  }
  switchGlobalStyles(theme: string) {
    let themeLink = this.document.getElementById(
      "global-styles"
    ) as HTMLLinkElement;
    if (themeLink && theme) {
      themeLink.href = theme + ".css";
    } else themeLink.href = "";
  }
}
