<div class="container-fluid bg-grey-light py-3">
    <div class="capabilities-bloc-container">
        <h2 class="main-title-style main-title-style-small text-center">Capabilities</h2>
        <div class="w-100 d-flex flex-wrap mt-3">
            <div class="col-lg-4 col-12 px-4">
                <img src="../../../../../assets/img/feature-1.jpg" class="w-100 mb-4">
                <p class="main-paragraph-style px-3">
                    Transforming your visionary ideas into fully-fledged projects has never been easier than with Heero Studio, the journey from concept to reality becomes a seamless and efficient process
                </p>
            </div>
            <div class="col-lg-4 col-12 px-4">
                <img src="../../../../../assets/img/feature-2.jpg" class="w-100 mb-4">
                <p class="main-paragraph-style px-3">
                    Experience a world of possibilities with Heero Studio's integrated options. offers a built-in features and tools that seamlessly work together to enhance your project development process.
                </p> 
            </div>
            <div class="col-lg-4 col-12 px-4">
                <img src="../../../../../assets/img/feature-3.jpg" class="w-100 mb-4">
                <p class="main-paragraph-style px-3">
                    Heero Studio project builder not only empowers you to create exceptional applications but also enables you to craft a cohesive and compelling brand experience.
                </p>
            </div>
        </div>
    </div>
</div>