import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './main/main.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: '/session/home',
    pathMatch: 'full',
  },
  {
    path: '', component: MainComponent,
    children: [
      {
        path: 'session',
        loadChildren: () => import('./session/session.module').then(m => m.SessionDemoModule)
      },
      {
        path: 'modeler',
        loadChildren: () => import('./modeler/modeler.module').then(m => m.ModelerModule)
      },
      {
        path: 'accountsManagement',
        loadChildren: () => import('./modules/accounts-management/accounts-management.module').then(m => m.AccountsManagementModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'blueprints',
        loadChildren: () => import('./modules/blueprints/blueprints.module').then(m => m.BlueprintsModule)
      },
      {
        path: 'generated-projects',
        loadChildren: () => import('./modules/project-generation/project-generation.module').then(m => m.ProjectGenerationModule)
      },
      {
        path: 'builder',
        loadChildren: () => import('./shared/modules/hro-page-builder-wrapper/hro-page-builder-wrapper.module').then(m => m.HroPageBuilderWrapperModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule {
}

