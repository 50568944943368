import {NgModule, Injector} from '@angular/core';
import { BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientXsrfModule} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { registerLocaleData} from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbActiveModal, NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import localeFr from '@angular/common/locales/fr';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthService } from './service/auth/auth.service';
import { ChankyaAppComponent} from './app.component';
import {AppRoutes, RoutingModule} from './app-routing.module';
import { MainComponent } from './main/main.component';
import { MenuToggleModule } from './core/menu/menu-toggle.module';
import { PageTitleService } from './core/page-title/page-title.service';
import { SessionDemoModule } from './session/session.module';
import {AuthInterceptor} from './service/auth/auth.interceptor';
import {AuthExpiredInterceptor} from './service/auth/auth-expired.interceptor';
import {ServiceLocator} from './service/ServiceLocator';
import {CustomTableEmitter} from './core/services/customTableEmitter';
import {APIService} from './service/api.service';
import {CustomBreadcrumbService} from './core/services/custom-breadcrumb.service';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {NgxSpinnerModule} from 'ngx-spinner';
import * as firebase from 'firebase';
import {MatBadgeModule} from '@angular/material/badge';
import {MatExpansionModule} from '@angular/material/expansion';
import {MenuItems, ModuleMenuItems} from "./core/menu/menu-items/menu-items";
import { SharedModuleModule } from './modules/shared-module/shared-module.module';
import { AutoCompleteModule } from "primeng/autocomplete";
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};



@NgModule({
  imports: [
    HttpClientXsrfModule.withOptions({cookieName: 'XSRF-TOKEN'}),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    RoutingModule,
    RouterModule.forRoot(AppRoutes),
    SessionDemoModule,
    NgbModalModule,
    PerfectScrollbarModule,
    MenuToggleModule,
    HttpClientModule,
    SharedModuleModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      preventDuplicates: true,
      maxOpened : 1,
      autoDismiss : true
    }),
    AngularFireModule.initializeApp(firebase),
    AngularFireAuthModule,
    AutoCompleteModule,
    BreadcrumbModule,
    MatBadgeModule,
    MatExpansionModule,
  ],
	declarations: [
		ChankyaAppComponent,
		MainComponent,
	],
	bootstrap: [ChankyaAppComponent],
	providers: [
    LocalStorageService,
    SessionStorageService,
		PageTitleService,
    APIService,
    CustomBreadcrumbService,
    CustomTableEmitter,
		NgbActiveModal,
    MenuItems,
    ModuleMenuItems,

    {
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthExpiredInterceptor,
			multi: true
		},
		AuthService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		}
	],
})
export class ChankyaAppModule {
	constructor(private injector: Injector) {
		ServiceLocator.injector = this.injector;
		registerLocaleData(localeFr, 'fr');

  }
}
